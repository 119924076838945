@import "../../base/variables-colors";
@import "../../base/variables-typography";
@import "../../base/variables-breakpoints";
@import "../../base/variables";
@import "../../base/mixins";

// Quote block
//
// Status: near final
//
// A citat/quote block with quote in brush script and a source. Can inherit colours from [standard section options](/patterns/#!/section/4.2), but can only use the bottom keyline.
//
// Styleguide 3.20

// Quote block width
//
// This module works with different standard column sizes, but doesn't use the standard ``Section-Inner`` classnames so that it can have a different default.
//
// .default! - no modifier: MD/50rem
// .Quote-Inner--xs - XS
// .Quote-Inner--sm - SM (standard text column width)
// .Quote-Inner--md - MD
// .Quote-Inner--lg - LG
// .Quote-Inner--xl - XL
// .Quote-Inner--xxl - XXL (full screen width)
//
// markup:
//      <link rel="stylesheet" href="static/modules/quote/quote.css"> <script> </script>
//      <div class="Section Quote">
//          <blockquote class="Section-Inner Quote-Inner {$modifiers}">
//              <div class="Quote-Text">
//                  Deserunt nostrud eu sint deserunt consectetur eiusmod amet.
//               </div>
//               <footer class="Quote-Source">
//                   Janne Andersson, fjärrvärmekund
//               </footer>
//           </blockquote>
//       </div>
//
// Styleguide 3.20.1

.Quote {
    &-Inner {
        position: relative;
        max-width: $width-md;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1rem;
        text-align: center;
        &--xxl {
            max-width: 9000px;
            max-width: 100vw;
        }
        &--xl {
            max-width: $width-xl;
        }
        &--lg {
            max-width: $width-lg;
        }
        &--md {
            // note this is the default
        }
        &--sm {
            max-width: $width-sm;
        }
        &--xs {
            max-width: $width-xs;
        }
        @media (max-width: $less-than-xs) {
            .Quote & {
                max-width: 19rem;
            }
        }
        
        &:before {
            display: none;
            @media screen AND (min-width: $xs-and-up) {
                content: '';
                display: block;
                width: 150px;
                height: 43px;
                margin: 0 auto;
                background-image: url("data:image/svg+xml,%3Csvg%20class%3D%27Quote-Separator%27%20width%3D%27250%27%20height%3D%2743%27%20viewBox%3D%270%200%20250%2043%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Ctitle%3Equote%20seperator%3C%2Ftitle%3E%3Cpath%20d%3D%27M119.059%206.248h-8.835v8.837l-6.25%206.249%206.25%206.248v8.837h8.835l6.25%206.248%206.248-6.248h8.836v-8.837l6.249-6.248-6.249-6.249V6.248h-8.836L125.309.001zM0%2021.875l86.888%201.75v-3.501zm163.4%201.751l86.888-1.75-86.888-1.75z%27%20fill%3D%27%23c29c0e%27%20fill-rule%3D%27evenodd%27%2F%3E%3C%2Fsvg%3E");
                background-repeat: no-repeat;
                background-position: 100% 50%;
                background-size: 100%;
                @supports ( animation:none ) {
                    opacity: 0;
                    animation: fade-in 1000ms forwards;
                    animation-delay: 500ms;
                }
            }
        }
    }
    &-Text {
        margin: 0;
        padding: $gutter 0;
        @include typo-serif();
        font-style: normal;
        font-size: 2em;
        line-height: 1.2;
        @supports ( animation:none ) {
            opacity: 0;
            animation: fade-in 1000ms forwards;
            animation-delay: 1200ms;
        }
        @media screen AND  (min-width: 360px) AND (max-width: $less-than-xs) {
            padding: $gutter*2 0;
            .Quote & {
                font-size: 2.4rem;
                line-height: 1.05;
            }
        }
        &-Inner {
            position: relative;
            display: inline-block;
            padding-left: 1.3em;
            padding-right: 1.1em;
            &:before,
            &:after {
                content: '“';
                position: absolute;
                top: -0.25em;
                left: 0;
                font-size: 2em;
            }
            &:after {
                content: '”';
                left: auto;
                right: 0;
            }
        }
    }
    &-Source {
        @include typo-smallcaps();
        margin: 0;
        font-size: 1rem;
        line-height: 1.2;
        text-transform: lowercase;
        @supports ( animation:none ) {
            opacity: 0;
            animation: fade-in 1000ms forwards;
            animation-delay: 1500ms;
        }
        @media (max-width: $less-than-xs) {
            font-size: $font-size-lg-sm;
        }
        &-More {
            display: block;
            margin: 0 auto;
            font-style: italic;
        }
    }
}